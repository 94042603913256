.update-select-form {
  width: 100%;
  height: 32px;
}

.update-select-form .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  text-align: start;
}
