.single-image-list .ant-image {
  height: 100%;
  width: 100%;
}

.ant-pro-card-body {
  padding-inline: 4px !important;
}

.ant-pro-table-extra {
  margin-bottom: 10px;
}

.ant-pro-table-list-toolbar-container {
  padding-block: 8px;
}
