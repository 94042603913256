.selectedRow {
  background-color: var(--adm-color-primary);
  color: #fff;
}

.ant-table-tbody > tr.selectedRow:hover > td:hover {
  background-color: var(--adm-color-primary);
  color: #fff;
}

.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}
