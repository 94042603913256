.break-detail-div {
  display: flex;
  height: calc(88vh - 60px);
  overflow: auto;
}

.page-header {
  padding: 0;
}

.page-header .ant-page-header-heading-title {
  white-space: normal;
  text-overflow: unset;
}

.break-detail-collapse > .ant-collapse-item-active > .ant-collapse-header {
  margin: 0;
}

.break-detail-collapse
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 12px;
}

.break-detail-desc-title > .ant-descriptions-header {
  margin: 8px 0;
}

.break-base-desc-title > .ant-descriptions-header {
  margin: 8px 0;
}

.no-margin-divider {
  margin: 0;
}

.break-detail-collapse .ant-descriptions-item-content {
  line-height: 32px;
}

.break-detail-collapse .ant-descriptions-item-label {
  line-height: 32px;
}

.break-base-desc-title .ant-descriptions-item-content {
  line-height: 32px;
}

.break-base-desc-title .ant-descriptions-item-label {
  line-height: 32px;
}
