.login-page {
  width: 100%;
  min-width: var(--min-width);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.login-content {
  height: 100%;
  padding: 24px;
}

.login-page-footer {
  flex: 1;
  text-align: center;
  height: 32px;
  font-size: 1rem;
  margin-bottom: 1rem;
}
