.update-input-form-txexarea {
  width: 100%;
  height: auto;
  /* margin-bottom: 14px; */
}

/* height: 150px; */
.update-input-form-txexarea .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  text-align: start;
}

.page-header {
  padding: 0;
}

.page-header-span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.info-comment-textarea {
  max-height: "100%";
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 0px 4px;
  overflow: hidden;
  background-color: #ffffff;
}

.editting-info-textarea {
  background-color: #ffffff;
  overflow: hidden;
}

.textarea-disbaled {
  color: rgba(0, 0, 0, 0.88) !important;
  background-color: rgba(0, 0, 0, 0) !important;
  overflow: hidden;
}
