.loading-page {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  min-width: var(--min-width);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-page-space {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
