.break-detail-div em {
  font-style: normal;
  font-weight: bold;
}

.emHead {
  font-style: normal;
  font-weight: bold;
  color: grey;
}

.break-detail-div p {
  /* white-space: nowrap !important; */
  margin: 0px;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.break-detail-div .ant-list-item-meta {
  margin-block-end: 0px !important;
}

.break-detail-div h4 {
  margin-block-end: 0px !important;
}
.pro-list-content-no-margin {
  margin-inline: 0 !important; /* 重置内联边距 */
  color: #172b4d;
}

.query-input {
  width: 100% !important; /* 使用 !important 确保覆盖其他样式 */
}

.full-width-input {
  width: 100% !important; /* 使用 !important 确保覆盖其他样式 */
}
