.update-upload {
  width: 100%;
}

.update-upload .ant-upload-select {
  margin-top: 4px;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.6;
}
