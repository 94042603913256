.logo-content {
  width: 100%;
  height: 30px;
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-content img {
  height: 100%;
}

.sider-trigger {
  position: absolute;
  left: 18px;
  top: 15px;
  font-size: 18px;
}

.navigation-layout {
  height: 100vh;
}

.navigation-content {
  height: 100%;
  padding: 8px 8px 0 8px;
  overflow: auto;
  background-color: #ffffff;
}

.navigation-header {
  padding: 0;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-avatar {
  margin-right: 24px;
}

.navigation-footer {
  height: 32px;
  line-height: 32px;
  font-size: 1rem;
  text-align: center;
  background-color: #ffffff;
}
