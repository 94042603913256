.mobile-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.mobile-top {
  border-bottom: solid 1px var(--adm-color-border);
  background: #ffffff;
  position: sticky;
  z-index: 998;
  top: 0;
  left: 0;
}

.mobile-body {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
}

.mobile-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #ffffff;
  border-top: solid 1px var(--adm-color-border);
}

.mobile-header {
  padding: 12px;
  background: #ffffff;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 998;
  top: 0;
  left: 0;
  border-top: solid 1px var(--adm-color-border);
}

.adm-pull-to-refresh {
  width: 100%;
  display: block;
}

.adm-spin-loading {
  display: block;
  margin: 0 auto;
}

.adm-popover-menu-item-icon {
  flex: none;
  padding-right: 8px;
  padding-top: 4px;
  font-size: 20px;
}

.adm-popover-menu-item {
  display: flex;
  padding-left: 12px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.adm-nav-bar-back {
  margin-right: 0;
  padding: 0;
}

.adm-nav-bar-back-arrow {
  font-size: 24px;
  margin: 0;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
  font-size: var(--adm-font-size-9);
  margin-bottom: 4px;
}

.adm-collapse .adm-list-body {
  border-radius: 0;
  margin-left: 12px;
  border-bottom: 0;
}

.adm-collapse .adm-list-item {
  padding-left: 0;
}

.adm-capsule-tabs-content {
  padding: 0;
}

.adm-card-body {
  padding: 0;
}

.adm-capsule-tabs-header {
  background-color: #ffffff;
  border: 0;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 100%;
  margin-right: 8px;
}

.ant-upload-list-item-thumbnail {
  pointer-events: none;
}

.ant-upload-list-item-name {
  pointer-events: none;
  color: var(--color);
}

.adm-form-item-label-attachment .adm-form-item-label {
  pointer-events: none;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.ant-upload-list-item-name {
  width: 1vw;
  word-break: break-all;
}
