.customer-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.customer-detail-top {
  width: 100%;
  border-bottom: solid 1px var(--adm-color-border);
  background: #ffffff;
  position: sticky;
  z-index: 998;
  top: 0;
}

.customer-detail-body {
  flex: 1;
  height: "100%";
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  /* margin-top: 45px; */
}

.customer-detail-bottom {
  width: 100%;
  background-color: #ffffff;
  border-top: solid 1px var(--adm-color-border);
  position: sticky;
  bottom: 0;
  margin-bottom: 1rem;
}

.customer-header {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid var(--adm-color-border);
  position: sticky;
  /* top: 45px; */
  z-index: 100;
}

.tabs {
  flex: 1;
}

.customer-content {
  overflow-y: scroll;
  height: "100%";
  margin-bottom: 50px;
  /* margin-top: 45px; */
}

.customer-tabbar {
  position: fixed;
  /* position: sticky; */
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff; /* 设置底部背景色，根据需要自定义 */
  z-index: 999; /* 如果有其他固定元素，可以调整z-index */
}

.popup-content {
  height: 100vh; /* 设置内容区域高度为视口高度，以占据整个屏幕 */
  display: flex;
  flex-direction: column;
}
