.update-multiple-select-form {
  width: 100%;
  line-height: 32px;
}

.update-multiple-select-form .ant-form-item {
  margin-bottom: 0;
}

.update-multiple-select-form .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  text-align: start;
}
