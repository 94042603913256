.page-header {
  padding: 0;
}

.page-header-span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* margin: 0 0 0 0 ; */
}

/* ant-page-header-heading  */
.info-comment {
  max-height: 312px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin: 8px 0;
  padding: 0 8px;
  overflow: auto;
  background-color: #ffffff;
}

.info-comment .ant-comment-inner {
  padding-block: 8px;
}

.editting-info {
  background-color: #ffffff;
  padding: 0 8px;
  overflow: auto;
}

.editting-quill {
  margin: 8px 0;
}

.user-comment .ql-editor {
  padding: 4px 10px;
}

.no-user-comment .ql-editor {
  padding: 0;
}
