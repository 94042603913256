.selectedRow {
  background-color: var(--adm-color-primary);
  color: #fff;
}

.ant-table-tbody > tr.selectedRow:hover > td:hover {
  background-color: var(--adm-color-primary);
  color: #fff;
}

/* .desTable,
.desTable>th,
.desTable>td {
  padding: 0px;
  padding-bottom: 0px;
} */

.ant-descriptions-row > th.desTable {
  padding: 0px;
}

.server-base-info-form .ant-upload-select {
  margin-top: 4px;
}
